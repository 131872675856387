import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { FormsModule } from '@angular/forms';
import { TimeagoPipe } from './pipe/timeago.pipe';
import { CheckoutComponent } from './checkout/checkout.component';

@NgModule({
  declarations: [NavbarComponent, SidebarComponent, FooterComponent, LoadingSpinnerComponent, TimeagoPipe, CheckoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule
  ],
  exports: [NavbarComponent, SidebarComponent, FooterComponent, LoadingSpinnerComponent, TimeagoPipe, CheckoutComponent]
})
export class SharedModule { }
