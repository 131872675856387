import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap, map } from 'rxjs/operators';
import { Lead } from '../shared/lead.model';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class LeadService {
  public leadSearch: string;
  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore, private afFunc: AngularFireFunctions) { 
    this.leadSearch='';
  }

  /*
    Get all leads owned by current user
   */
  getAgentLead(){
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if(user) {
          return this.db.collection<Lead>('leads', ref =>
          ref.where('agent','==',user.uid).orderBy('date')
          )
          .valueChanges({idField:'id'});
        } else {
          return [];
        }
      })
    )
  }
  /*
    Get all leads that haven't been aqcuired
   */
  getAvailableLeads(){
    // let availLeads = this.afFunc.httpsCallable('getAvailableLeads');
    // console.log(availLeads);
    // return availLeads;

    return this.db.collection<Lead>('leads', ref =>
      ref.where('agent','==',false).orderBy('date')
      )
      .valueChanges( {idField: 'id'} );
  }

  // testGet(){
  //   let availLeads = this.afFunc.httpsCallable('getAvailableLeads');
  //   return availLeads({leads:'lead'});
  // }
  buyLead(id: string){
    let buyLead = this.afFunc.httpsCallable('buyLead');
    return buyLead({leadId: id});
  }

  deleteLead(id: string){
    // console.log(id);
    let deleteLead = this.afFunc.httpsCallable('deleteLead');
    return deleteLead({leadId: id});
  }
}
