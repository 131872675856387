import { Component, OnInit, Input, HostListener } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import swal from 'sweetalert2';

declare var StripeCheckout: StripeCheckoutStatic;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  constructor(private afAuth: AngularFireAuth, private afFunc: AngularFireFunctions) {}

  @Input() amount;
  @Input() credits;
  @Input() description;
  @Input() middle;

  handler: StripeCheckoutHandler;

  confirmation: any;
  loading = false;
  user;

  ngOnInit() {
    this.user = this.afAuth.auth.currentUser;
    this.handler = StripeCheckout.configure({
      key: 'pk_test_51HAEuSJRdljqv2CHyk1iJ4lV3fzxhSToU8slPKYkxq0OqbFoTp1ulZFXjmXLk3vdR5bLQS73VUOSNw1uf479SjKR005jNbB8KL',
      image: './assets/img/icon.png',
      locale: 'auto',
      currency: 'zar',
      source: async (source) => {
        this.loading = true;
        const fun = this.afFunc.httpsCallable('stripeCreateCharge');
        this.confirmation = await fun({ source: source.id, amount: this.amount }).toPromise().then(()=> {
          swal.fire("Successfully Purchased!", "You have successfully purchased "+this.credits+" credits","success");
        }).catch(() =>{
          swal.fire("Unsuccessful", "An error has occured please try again later.","error")
        });
        this.loading = false;
      }
    });
  }

  // Open the checkout handler
  async checkout(e) {
    const user = this.afAuth.auth.currentUser;
    this.handler.open({
      name: 'Relead Package Purchase',
      description: this.description,
      amount: this.amount,
      email: user.email,
    });
    e.preventDefault();
  }

  // Close on navigate
  @HostListener('window:popstate')
  onPopstate() {
    this.handler.close();
  }

}