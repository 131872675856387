import { Component, OnInit, OnDestroy } from '@angular/core';
import { CreditService } from 'src/app/services/credit.service';
import { Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'design_app', class: '' },
    { path: '/leads', title: 'Leads', icon: 'users_circle-08', class: ''},
    { path: '/credits', title: 'Credits', icon: 'business_money-coins', class: ''},
    { path: '/settings', title: 'Settings',  icon: 'loader_gear', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  menuItems: any[];
  sub: Subscription;

  constructor(public creditService: CreditService,
    public afAuth: AngularFireAuth, 
    public toastr: ToastrService,
    private router: Router ) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.sub = this.creditService.getCreditBalance();
  }
  ngOnDestroy(){
    this.sub.unsubscribe();
  }
  isMobileMenu() {
      if ( window.innerWidth > 991) {
          return false;
      }
      return true;
  }

  signOut(){
    this.afAuth.auth.signOut().then(()=>
      {
        this.toastr.info('<span class="now-ui-icons ui-1_bell-53"></span> You have have been logged out.','', {
          timeOut: 8000,
          closeButton: true,
          enableHtml: true,
          toastClass: "alert alert-primary alert-with-icon",
          positionClass: 'toast-top-center'
        });
        this.router.navigateByUrl('/login');
      }
    );
  }

}
