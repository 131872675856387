<div class="logo">
    <a routerLink="/dashboard" class="simple-text logo-mini">
        <img src="assets/img/icon.png" alt="">
    </a>
    <a routerLink="/dashboard" class="simple-text logo-normal">
      <!-- <div class="logo-img"> -->
          <!-- <img src="./assets/img/logo.png"/> -->
          Relead
      <!-- </div> -->
    </a>
   
    <!-- <a href="#" class="simple-text logo-normal">
        Relead
    </a> -->
</div>
<div class="sidebar-wrapper">
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a [routerLink]="[menuItem.path]">
                <i class="now-ui-icons {{menuItem.icon}}"></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
        <li routerLinkActive="active" class="nav-item">
            <a (click)="signOut()">
                <i class="now-ui-icons media-1_button-power"></i>
                <p>Sign Out</p>
            </a>
        </li>
    </ul>
</div>
