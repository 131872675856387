import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { switchMap, map } from 'rxjs/operators';
import { Lead } from '../shared/lead.model';
import { Credit, AgentCredit } from '../shared/credit.model';

@Injectable({
  providedIn: 'root'
})
export class CreditService {
  creditBalance: number;
  agentCredits: AgentCredit;
  pageSize = 10;
  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore, private afFunc: AngularFireFunctions) { 
    this.creditBalance=0;
  }
  /*
    Get all leads owned by current user
   */
  getCreditBalance(){
    return this.db.collection<AgentCredit>('agents').doc(this.afAuth.auth.currentUser.uid).valueChanges()
      .subscribe(value => {
        this.agentCredits=value;
        this.creditBalance=this.agentCredits.credits_balance;
    })
  }
  
  getCreditsHistory(){
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if(user) {
          return this.db.collection<Credit>('credits', ref =>
          ref.where('agent','==',user.uid).orderBy('date','desc')
          )
          .valueChanges();
        } else {
          return [];
        }
      })
    )
  }

  
  //To Do: Change to api call
  buyCredits(amount: number){
    const buyCredits = this.afFunc.httpsCallable('buyCredits');
    return buyCredits({value: amount});
  }
}
